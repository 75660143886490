
import Typewriter from 'typewriter-effect/dist/core';



export const typewriter = (dom, stringArr, config = {}) => {
  console.log('stringArr', stringArr)
  var typewriter = new Typewriter(dom, {
    typeSpeed: 80,
    backSpeed: 75,
    startDelay: 1000,
    backDelay: 2000,
    loop: false,
    showCursor: false,
    ...config
  })
  const innerList = [...stringArr]
  typewriter.start()
  innerList.forEach(item => {
    typewriter.typeString(item)

  })

}



export const typeWriterString = (dom, string, config = {}) => {
  var typewriter = new Typewriter(dom, {
    typeSpeed: 800,
    loop: false,
    ...config
  })

  typewriter.stop().pasteString(string).start();
}